import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import {ConfigProvider} from "antd";
import 'moment/locale/es';
import locale from 'antd/es/locale/es_ES';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import store from "./store";
import {Provider} from 'react-redux';
import Login from "./app/login/Login";
import './app.css';
import App from "./app/App";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider locale={locale}>
                <BrowserRouter>
                    <Switch>
                        <Route path='/login' exact component={Login}/>
                        <Route path='/:organization/:group/:tool' render={
                            (props) => (
                                <App pathInfo={{
                                    group: props.match.params.group,
                                    tool: props.match.params.tool,
                                    organization: props.match.params.organization,
                                }}
                                />
                            )
                        }/>
                        <Route path='/*' exact component={App}/>
                    </Switch>
                </BrowserRouter>
            </ConfigProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
