import Group from "../../model/group/group";

export const SELECT_GROUP = 'SELECT_GROUP';

// state
export interface GroupMenuState {
    selected: Group;
}

// actions
interface SelectGroupAction {
    type: typeof SELECT_GROUP
    group: Group;
}

// export
export type GroupMenuActionTypes = SelectGroupAction