import {GroupMenuActionTypes, GroupMenuState, SELECT_GROUP} from "./types";

const initialState: GroupMenuState = {
    selected: {name: '', tools: [], code: '', category: ''}
};

export default function groupMenuReducer(
    state = initialState,
    action: GroupMenuActionTypes
): GroupMenuState {
    switch (action.type) {
        case SELECT_GROUP:
            return {
                ...state,
                selected: action.group,
            };
        default:
            return state;
    }
}