import {LOG_IN, LOG_OUT, LoginActionTypes, LoginState} from "./types";

const initialState: LoginState = {
    user: {name: '', roles: [], groups: [], organization: {name: '', code: '',},},
    isLogged: false,
};

export default function loginReducer(
    state = initialState,
    action: LoginActionTypes
): LoginState {
    switch (action.type) {
        case LOG_IN:
            return {
                ...state,
                isLogged: true,
                user: action.user,
            };
        case LOG_OUT:
            return {
                ...state,
                isLogged: false,
                user: initialState.user,
            };
        default:
            return state;
    }
}