import {Tool} from "../../model/tool/tool";

export const SELECT_TOOL = 'SELECT_TOOL';

// state
export interface ToolMenuState {
    selected: Tool;
}

// actions
interface SelectToolAction {
    type: typeof SELECT_TOOL
    tool: Tool;
}

// export
export type ToolMenuActionTypes = SelectToolAction