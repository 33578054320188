import React from "react";
import {Affix, Avatar, Button, Col, Popconfirm, Row, Typography} from "antd";
import avatar from '../../user.png';
import {CloseOutlined, LogoutOutlined, MenuOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {logout} from "../login/loginActions";
import MainHeaderMenu from "./MainHeaderMenu";
import {BellOutlined} from "@ant-design/icons/lib";
import logo from '../../logo.png';
import User from "../../model/user/user";
import {ApplicationState} from "../../store";
import Group from "../../model/group/group";

interface Props {
    onLogout: () => void;
    user: User;
    selectedGroup: Group;
}

interface State {
    collapsedMenu: boolean,
    lastSelected: Group | undefined,
}

class MainHeader extends React.Component<Props, State> {

    state: State = {
        collapsedMenu: true,
        lastSelected: undefined,
    }

    menuElement: any;

    toggleCollapsed = () => {
        this.setState({collapsedMenu: !this.state.collapsedMenu,});
    }

    collapse = (b: boolean) => {
        this.setState({collapsedMenu: b,});
    }

    checkCollapse = () => {
        const {selectedGroup} = this.props;
        if (this.state.lastSelected?.code !== selectedGroup.code) {
            this.setState({lastSelected: selectedGroup}, () => this.collapse(true));
        }
    }

    render() {
        const {Text} = Typography;
        const {user, onLogout, selectedGroup} = this.props;
        this.checkCollapse();
        return (
            <Row>
                <Col xs={{span: 24}}>
                    <Row>
                        <Col xs={{span: 24}} md={{span: 0}}>
                            <Row justify="space-between">
                                <Col xs={{span: 5}} md={{span: 0}} style={{padding: '20px 0px 10px 20px'}}
                                     onClick={this.toggleCollapsed}>
                                    {React.createElement(
                                        this.state.collapsedMenu ? MenuOutlined : CloseOutlined
                                    )}
                                </Col>
                                <Col xs={{span: 10}} md={{span: 0}}
                                     style={{padding: '20px 0px 10px 0px', textAlign: 'center'}}>
                                    <Text strong>{selectedGroup.name}</Text>
                                </Col>
                                <Col xs={{span: 5}} md={{span: 0}}
                                     style={{padding: '20px 20px 10px 0px', textAlign: 'right'}}>
                                    <BellOutlined/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{span: this.state.collapsedMenu ? 0 : 24}} md={{span: 0}} style={{paddingTop: 10}}>
                            <div ref={(divElement) => {
                                if (divElement)
                                    this.menuElement = divElement
                            }}>
                                <MainHeaderMenu/>
                            </div>
                        </Col>
                        <Col xs={{span: 0}} md={{span: 24}}>
                            <Affix offsetTop={0}>
                                <Row align="middle" style={{backgroundColor: 'white'}}>
                                    <Col md={{span: 2, offset: 1}}>
                                        <img src={logo} alt='logo' width={50} height={50}/>
                                    </Col>
                                    <Col md={{span: 3, offset: 11}}>
                                        <img src={logo} alt='logo' width={50} height={50}/>
                                    </Col>
                                    <Col md={{span: 4, offset: 0}} style={{textAlign: 'right', paddingRight: 10}}>
                                        <Text strong>{user.name}</Text><br/>
                                        <Text>{user.groups[0].name}</Text>
                                    </Col>
                                    <Col md={{span: 1, offset: 0}} style={{paddingRight: 10}}>
                                        <Avatar
                                            src={avatar}
                                            alt="Han Solo"
                                        />
                                    </Col>
                                    <Col md={{span: 1, offset: 0}}>
                                        <Popconfirm
                                            placement="bottomRight"
                                            title={'¿Quieres cerrar sesión?'}
                                            okText="Si"
                                            cancelText="No"
                                            onConfirm={onLogout}
                                        >
                                            <Button type="default" shape="circle" size={"small"}
                                                    icon={<LogoutOutlined style={{color: 'red'}}/>}/>
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            </Affix>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            selectedGroup: state.groupMenu.selected,
            user: state.login.user,
        }
    },
    dispatch => {
        return {
            onLogout: () => dispatch(logout())
        }
    }
)(MainHeader);