import React from "react";
import {Badge, Divider, Menu, Typography} from "antd";
import Version from "../../Version";
import {Link} from "react-router-dom";
import Group from "../../model/group/group";
import {GROUP_CATEGORY} from "../../model/group/constants";
import Organization from "../../model/organization/organization";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import {selectGroup} from "./groupMenuActions";
import {ClickParam} from "antd/lib/menu";
import {selectTool} from "../tool/toolMenuActions";
import {Tool} from "../../model/tool/tool";

interface Props {
    availableGroups: Group[];
    organization: Organization;
    onSelectGroup: (g: Group) => void;
    onSelectTool: (t: Tool) => void;
    selected: Group;
}

class GroupMenu extends React.Component<Props> {

    rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];

    state = {
        openKeys: ['sub1'],
    };

    componentDidMount() {
        const {availableGroups, selected, onSelectGroup} = this.props;
        if (selected.code === '') {
            onSelectGroup(availableGroups[0]);
        }
    }

    onOpenChange = (openKeys: string[]) => {
        const latestOpenKey: string = openKeys.find(key => this.state.openKeys.indexOf(key) === -1) || '';
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({openKeys});
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };

    handleClick = (e: ClickParam) => {
        const {availableGroups, onSelectGroup} = this.props;
        const selected = availableGroups.find((g: Group) => g.code === e.key);
        if (selected !== undefined) {
            onSelectGroup(selected);
        }
    }

    checkDefault = () => {
        const {availableGroups, selected, onSelectGroup, onSelectTool} = this.props;
        if (availableGroups.length > 0) {
            if (selected.code === '') {
                onSelectGroup(availableGroups[0]);
            }
            if (availableGroups[0].tools.length > 0) {
                onSelectTool(availableGroups[0].tools[0]);
            }
        }
    }

    render() {
        const {SubMenu} = Menu;
        const {Text} = Typography;
        const {availableGroups, organization, selected} = this.props;
        this.checkDefault();
        return (
            <Menu
                onClick={this.handleClick}
                defaultSelectedKeys={[selected.code]}
                defaultOpenKeys={['sub1']}
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
                mode="inline">
                <SubMenu key="sub1" title={<span><Text strong>Cursos</Text></span>}>
                    {availableGroups.filter((g: Group) => g.category === GROUP_CATEGORY.COURSE).map(
                        (g: Group) => (
                            <Menu.Item key={g.code}>
                                <Link to={'/' + organization.code + '/' + g.code + '/' + g.tools[0].code}>
                                    <Text>{g.name}</Text>
                                </Link>
                            </Menu.Item>
                        )
                    )}
                </SubMenu>
                <SubMenu key="sub2" title={<span><Badge count={1} dot/><Text strong>Comunidades</Text></span>}>
                    {availableGroups.filter((g: Group) => g.category === GROUP_CATEGORY.CLOSED_COMMUNITY).map(
                        (g: Group) => (
                            <Menu.Item key={g.code}>
                                <Link to={'/' + organization.code + '/' + g.code + '/' + g.tools[0].code}>
                                    <Text>{g.name}</Text>
                                </Link>
                            </Menu.Item>
                        )
                    )}
                </SubMenu>
                <SubMenu key="sub3" title={<span><Badge count={1} dot/><Text strong>Publico</Text></span>}>
                    {availableGroups.filter((g: Group) => g.category === GROUP_CATEGORY.OPEN_COMMUNITY).map(
                        (g: Group) => (
                            <Menu.Item key={g.code}>
                                <Link to={'/' + organization.code + '/' + g.code + '/' + g.tools[0].code}>
                                    <Text>{g.name}</Text>
                                </Link>
                            </Menu.Item>
                        )
                    )}
                </SubMenu>
                <Divider/>
                <div style={{paddingTop: 25, textAlign: 'center'}}>
                    <Version/>
                </div>
            </Menu>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            availableGroups: state.login.user.groups,
            organization: state.login.user.organization,
            selected: state.groupMenu.selected,
        }
    },
    dispatch => {
        return {
            onSelectGroup: (g: Group) => dispatch(selectGroup(g)),
            onSelectTool: (t: Tool) => dispatch(selectTool(t)),
        }
    }
)(GroupMenu);