import {SELECT_TOOL, ToolMenuActionTypes, ToolMenuState} from "./types";

const initialState: ToolMenuState = {
    selected: {code: '', name: ''}
};

export default function toolMenuReducer(
    state = initialState,
    action: ToolMenuActionTypes
): ToolMenuState {
    switch (action.type) {
        case SELECT_TOOL:
            return {
                ...state,
                selected: action.tool,
            };
        default:
            return state;
    }
}