import User from "../../model/user/user";
import {LOG_IN, LOG_OUT, LoginActionTypes} from "./types";

export function login(user: User): LoginActionTypes {
    return {
        type: LOG_IN,
        user: user,
    }
}

export function logout(): LoginActionTypes {
    return {
        type: LOG_OUT
    }
}