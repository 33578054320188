import React from "react";
import {Avatar, Comment, List, Space, Tooltip, Typography} from "antd";
import {LikeOutlined, MessageOutlined} from "@ant-design/icons";
import moment from "moment";
import user from '../../user.png';

class Forum extends React.Component<any, any> {

    render() {
        const {Title, Paragraph, Text} = Typography;
        const data = [
            {
                author: 'Han Solo',
                avatar: user,
                content: (
                    <a href={'#'}>
                        <Typography>
                            <Text strong underline>in iaculis tortor maximus ut</Text>
                        </Typography>
                    </a>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Han Solo',
                avatar: user,
                content: (
                    <a href={'#'}>
                        <Typography>
                            <Text strong underline>placerat sit amet urna a</Text>
                        </Typography>
                    </a>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Han Solo',
                avatar: user,
                content: (
                    <a href={'#'}>
                        <Typography>
                            <Text strong underline>Proin sed eros in sapien accumsan pretium et quis tellus</Text>
                        </Typography>
                    </a>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Han Solo',
                avatar: user,
                content: (
                    <a href={'#'}>
                        <Typography>
                            <Text strong underline>Integer sapien nunc</Text>
                        </Typography>
                    </a>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Han Solo',
                avatar: user,
                content: (
                    <a href={'#'}>
                        <Typography>
                            <Text strong underline>Orci varius natoque penatibus et magnis dis parturient montes</Text>
                        </Typography>
                    </a>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Braulio López',
                avatar: user,
                content: (
                    <a href={'#'}>
                        <Typography>
                            <Text strong underline>Lorem ipsum dolor sit amet</Text>
                        </Typography>
                    </a>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(2, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(2, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
        ];

        class IconText extends React.Component<{ icon: any, text: any }> {
            render() {
                let {icon, text} = this.props;
                return (
                    <Space>
                        {React.createElement(icon)}
                        {text}
                    </Space>
                );
            }
        }

        return (
            <List
                itemLayout="horizontal"
                size="small"
                pagination={{
                    onChange: page => window.scrollTo(0, 0),
                    pageSize: 5,
                    size: 'small',
                    position: 'bottom'
                }}
                dataSource={data}
                renderItem={item => (
                    <li>
                        <Comment
                            actions={[
                                <span key="comment-basic-like">
                                <IconText icon={LikeOutlined} text='52' key="list-vertical-like-o"/>
                                </span>,
                                <span key="comment-basic-commet">
                                <IconText icon={MessageOutlined} text='2' key="list-vertical-message"/>
                                </span>,
                            ]}
                            author={item.author}
                            avatar={
                                <Avatar
                                    size='large'
                                    src={item.avatar}
                                    alt="Han Solo"
                                />
                            }
                            content={item.content}
                            datetime={
                                <Tooltip
                                    title={moment('15-04-2020 23:54:44', 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}>
                                    <span>{moment('15-04-2020 23:54:44', 'DD-MM-yyyy HH:mm:ss').fromNow()}</span>
                                </Tooltip>
                            }
                        />
                    </li>
                )}
            />
        );
    }
}

export default Forum;
