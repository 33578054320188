import React from "react";
import {LoginState} from "./types";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import {Avatar, Card, Col, message, Row, Space, Typography} from "antd";
import User from "../../model/user/user";
import {UserOutlined} from "@ant-design/icons/lib";
import Version from "../../Version";
import {login} from "./loginActions";
import {GROUP_CATEGORY} from "../../model/group/constants";
import {TOOL} from "../../model/tool/constants";
import {roleTranslation, USER_ROLE} from "../../model/user/constants";
import {selectGroup} from "../menu/groupMenuActions";
import {selectTool} from "../tool/toolMenuActions";

interface Props {
    loginInfo: LoginState;
    onLogin: (u: User) => void;
}

class Login extends React.Component<Props, any> {

    users: User[] = [
        {
            name: 'Braulio López',
            organization: {name: 'Colegio Moderno', code: 'colegio-moderno'},
            groups: [
                {
                    category: GROUP_CATEGORY.COURSE,
                    code: '1BA2020-B',
                    name: '1º Basico B',
                    tools: [TOOL.FORUM, TOOL.HOMEWORK, TOOL.MARK,]
                },
                {
                    category: GROUP_CATEGORY.OPEN_COMMUNITY,
                    code: 'ALL',
                    name: 'Colegio Moderno',
                    tools: [TOOL.FORUM, TOOL.BULLETIN,]
                },
                {
                    category: GROUP_CATEGORY.CLOSED_COMMUNITY,
                    code: 'AJEDREZ',
                    name: 'Ajedrez',
                    tools: [TOOL.FORUM, TOOL.BULLETIN,]
                },
                {
                    category: GROUP_CATEGORY.CLOSED_COMMUNITY,
                    code: 'FUTBOL',
                    name: 'Futbol',
                    tools: [TOOL.FORUM, TOOL.BULLETIN,]
                }
            ],
            roles: [USER_ROLE.STUDENT]
        },
        {
            name: 'Fabian Torres',
            organization: {name: 'Colegio Moderno', code: 'colegio-moderno'},
            groups: [
                {
                    category: GROUP_CATEGORY.CLOSED_COMMUNITY,
                    code: 'ADM',
                    name: 'Administración',
                    tools: [TOOL.FORUM, TOOL.BULLETIN,]
                }
            ],
            roles: [USER_ROLE.ADMINISTRATOR]
        },
        {
            name: 'Juan Perez',
            organization: {name: 'Colegio Moderno', code: 'colegio-moderno'},
            groups: [
                {
                    category: GROUP_CATEGORY.COURSE,
                    code: '1BA2020-B',
                    name: '1º Basico B',
                    tools: [TOOL.FORUM, TOOL.BULLETIN, TOOL.MARK]
                }
            ],
            roles: [USER_ROLE.GUARDIAN]
        },
        {
            name: 'Pablo Jaramillo',
            organization: {name: 'Colegio Moderno', code: 'colegio-moderno'},
            groups: [
                {
                    category: GROUP_CATEGORY.COURSE,
                    code: '1BA2020-B',
                    name: '1º Basico B',
                    tools: [TOOL.FORUM, TOOL.BULLETIN, TOOL.MARK]
                }
            ],
            roles: [USER_ROLE.TEACHER]
        },
    ];

    onSelectUser(index: number): void {
        switch (index) {
            case 0:
                this.props.onLogin(this.users[index]);
                break;
            default:
                message.error('Este usuario aun no está disponible 😅');
        }
    }

    render() {
        const {Text} = Typography;
        const {isLogged} = this.props.loginInfo;
        if (isLogged) {

            return (<Redirect to='/colegio-moderno/3-basico-a-2020/forum'/>);
        } else {
            return (
                <Row>
                    <Col xs={{span: 24}}>
                        <Row justify='end'>
                            <Col xs={{span: 24}} style={{textAlign: 'right'}}>
                                <Version/>
                            </Col>
                        </Row>
                        <Row justify='center'>
                            <Col xs={{span: 24}}>
                                <Space direction='vertical' className='center-horizontal'>
                                    {this.users.map((user: User, index: number) => (
                                        <Card key={index} hoverable onClick={() => this.onSelectUser(index)}>
                                            <Row>
                                                <Col xs={{span: 7}}>
                                                    <Avatar icon={<UserOutlined/>}/>
                                                </Col>
                                                <Col xs={{span: 17}}>
                                                    <Text strong>{user.name}</Text><br/>
                                                    <Text>{user.roles.map((r: string) => roleTranslation(r)).join(", ")}</Text>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))}
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        }
    }
}

export default connect(
    (state: ApplicationState) => {
        return {loginInfo: state.login};
    },
    dispatch => {
        return {
            onLogin: (u: User) => {
                dispatch(login(u));
                dispatch(selectGroup(u.groups[0]));
                dispatch(selectTool(u.groups[0].tools[0]));
            },
        }
    }
)(Login);