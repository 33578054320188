export const USER_ROLE = {
    STUDENT: 'STUDENT',
    TEACHER: 'TEACHER',
    GUARDIAN: 'GUARDIAN',
    ADMINISTRATOR: 'ADMINISTRATOR',
};

export function roleTranslation(role: string): string {
    switch (role) {
        case USER_ROLE.STUDENT:
            return 'Alumno';
        case USER_ROLE.TEACHER:
            return 'Profesor';
        case USER_ROLE.GUARDIAN:
            return 'Apoderado';
        case USER_ROLE.ADMINISTRATOR:
            return 'Administrador';
        default:
            return role;
    }
}