import React from "react";
import {Avatar, Comment, List, Tooltip, Typography} from "antd";
import user from "../../user.png";
import moment from "moment";

class Bulletin extends React.Component<any> {
    render() {
        const {Title, Paragraph} = Typography;
        const data = [
            {
                author: 'Tia Juana',
                avatar: user,
                content: (
                    <Typography>
                        <Title level={4}>in iaculis tortor maximus ut</Title>
                        <Paragraph>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ligula lacus, blandit eu ante
                            eu,
                            euismod bibendum justo. Integer suscipit lacinia sem a maximus. Cras porta ullamcorper
                            augue, eu
                            pharetra ante blandit ac. Praesent et dui et sapien aliquet dignissim. Pellentesque et nibh
                            in
                            diam congue varius vel vitae lorem. Sed eget tempus mi, hendrerit ullamcorper lorem.
                            Pellentesque ipsum tellus, lacinia at accumsan quis, commodo vitae sapien. Vivamus et
                            eleifend
                            quam. Nam euismod neque sed ultricies elementum. Maecenas eu turpis sollicitudin, luctus
                            mauris
                            et, faucibus mauris. Proin eleifend commodo augue id pretium. Donec eu ipsum ac lectus
                            pellentesque tempus. Sed auctor, odio at placerat blandit, dui erat ultricies tortor, eu
                            convallis sapien tellus iaculis turpis.
                        </Paragraph>
                        <Paragraph>
                            Curabitur viverra urna eget pharetra fringilla. Donec pretium fermentum mi eget facilisis.
                            Suspendisse blandit, mi vel congue faucibus, arcu mauris auctor justo, vitae porta ante leo
                            quis
                            ex. Mauris ac pharetra nibh, vel facilisis erat. Nam vitae tortor massa. Maecenas et gravida
                            odio, viverra posuere nulla. Praesent nec est vitae magna scelerisque sodales. Pellentesque
                            congue magna nec tellus dictum lobortis. Ut eu sodales neque. Nunc tincidunt tincidunt
                            metus, eu
                            tristique magna lobortis egestas. In hac habitasse platea dictumst. Interdum et malesuada
                            fames
                            ac ante ipsum primis in faucibus. Nullam in sapien suscipit, tristique ligula sit amet,
                            pellentesque tortor. Mauris sollicitudin dapibus est, a lacinia nibh semper sed.
                        </Paragraph>
                    </Typography>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Don Pepe',
                avatar: user,
                content: (
                    <Typography>
                        <Title level={4}>placerat sit amet urna a</Title>
                        <Paragraph>
                            Cras sed nunc eu libero commodo pulvinar. Aliquam euismod semper laoreet. Duis consequat
                            urna
                            justo, sed faucibus odio viverra vitae. Mauris sit amet fringilla velit. Integer non risus
                            sed
                            mauris efficitur ornare. Fusce aliquet blandit tortor, ac iaculis tortor cursus eu. Etiam
                            fermentum urna neque, ac tristique mi tempus dictum. Morbi quis turpis nibh.
                        </Paragraph>
                        <Paragraph>
                            Integer sodales, leo eu dictum venenatis, neque mi fermentum lacus, in ultrices velit nulla
                            et
                            massa. Praesent placerat urna posuere, efficitur libero eget, venenatis odio. Phasellus
                            hendrerit turpis vitae massa elementum finibus. Proin leo urna, sodales eu iaculis maximus,
                            sodales nec lectus. Mauris tempus urna lacus, sit amet ornare lorem maximus in. Phasellus
                            porttitor enim ac eros efficitur, ut feugiat sem luctus. Aenean luctus, orci ut suscipit
                            lacinia, ipsum lorem malesuada libero, in maximus nulla urna rutrum arcu. Quisque mollis mi
                            nunc, vitae finibus arcu faucibus a. Vivamus non lectus non erat mollis efficitur eu ut sem.
                            Integer laoreet neque et est convallis, at condimentum enim pretium. Phasellus gravida nibh
                            at
                            ex pellentesque rhoncus. Donec sapien arcu, tincidunt eget elementum vitae, venenatis ac
                            nisl.
                            Nullam urna nunc, porttitor eu fermentum at, elementum eget urna.
                        </Paragraph>
                    </Typography>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Han Solo',
                avatar: user,
                content: (
                    <Typography>
                        <Title level={4}>Proin sed eros in sapien accumsan pretium et quis tellus</Title>
                        <Paragraph>
                            Nullam leo elit, mollis eget malesuada id, aliquet vel mi. Cras interdum libero orci, in
                            fermentum enim placerat non. Aenean a luctus massa. Aliquam ultricies arcu a dictum
                            efficitur.
                            Proin sed eros in sapien accumsan pretium et quis tellus. Vivamus hendrerit metus eu velit
                            pulvinar, vel rhoncus tellus bibendum. Donec semper sagittis ipsum, in consectetur risus
                            faucibus fringilla. Proin aliquam, neque iaculis interdum accumsan, nibh urna malesuada
                            eros,
                            quis sodales enim enim at erat. Praesent iaculis libero at turpis tincidunt, eget convallis
                            ligula tincidunt. Cras vel nunc sit amet orci maximus viverra in at nunc.
                        </Paragraph>
                    </Typography>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Han Solo',
                avatar: user,
                content: (
                    <Typography>
                        <Title level={4}>Integer sapien nunc</Title>
                        <Paragraph>
                            Sed at lobortis lacus, vel congue elit. Fusce nisi augue, viverra eu volutpat in, viverra ac
                            libero. Donec sed enim elit. Ut scelerisque, lorem ac finibus auctor, dui ex aliquet sapien,
                            et
                            rutrum sapien nisi id eros. Quisque pharetra risus turpis. Curabitur nunc turpis, dignissim
                            ac
                            risus in, molestie sollicitudin lacus. Nunc odio mi, euismod a condimentum sed, dignissim at
                            nulla. Proin a semper libero. Vivamus blandit dictum quam et condimentum. Nulla porttitor
                            bibendum nulla gravida finibus. Integer sapien nunc, molestie in nunc nec, luctus ultricies
                            diam. Suspendisse porttitor risus quis lorem viverra, nec cursus ligula imperdiet. Donec
                            rhoncus
                            ultricies odio, sed mattis velit viverra eget.
                        </Paragraph>
                    </Typography>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Han Solo',
                avatar: user,
                content: (
                    <Typography>
                        <Title level={4}>Orci varius natoque penatibus et magnis dis parturient montes</Title>
                        <Paragraph>
                            Duis sit amet ipsum eget est aliquam hendrerit non eu arcu. Nam volutpat tellus sit amet
                            nunc
                            dictum, eu porttitor nisl accumsan. Etiam eu neque ullamcorper ex pulvinar tempus. Lorem
                            ipsum
                            dolor sit amet, consectetur adipiscing elit. Mauris eu maximus ipsum. Praesent sodales
                            pharetra
                            augue. Integer lectus turpis, aliquam at porta ac, aliquet mollis lectus. Phasellus interdum
                            ligula id neque suscipit, id mattis justo suscipit. Phasellus cursus urna non erat blandit
                            aliquam. Pellentesque gravida dictum tincidunt. In nec dui elementum, ultrices felis vel,
                            ullamcorper orci. Sed faucibus felis vel turpis eleifend, sed sodales ipsum dignissim. Nam
                            quis
                            varius ante. Orci varius natoque penatibus et magnis dis parturient montes, nascetur
                            ridiculus
                            mus.
                        </Paragraph>
                    </Typography>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(1, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(1, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
            {
                author: 'Braulio López',
                avatar: user,
                content: (
                    <Typography>
                        <Title level={4}>Lorem ipsum dolor sit amet</Title>
                        <Paragraph>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus turpis sodales
                            placerat
                            mattis. Donec interdum convallis massa, sit amet blandit leo cursus ut. Vestibulum et
                            elementum
                            est. Nulla rhoncus elit massa, in iaculis tortor maximus ut. Suspendisse id tortor ac nulla
                            dictum porttitor. Nunc odio lectus, maximus in finibus in, consequat nec quam. Phasellus in
                            vehicula lectus.
                        </Paragraph>
                    </Typography>
                ),
                datetime: (
                    <Tooltip
                        title={moment()
                            .subtract(2, 'days')
                            .format('DD-MM-YYYY HH:mm:ss')}
                    >
        <span>
          {moment()
              .subtract(2, 'days')
              .fromNow()}
        </span>
                    </Tooltip>
                ),
            },
        ];
        return (
            <List
                itemLayout="horizontal"
                size="small"
                pagination={{
                    onChange: page => window.scrollTo(0, 0),
                    pageSize: 5,
                    size: 'small',
                    position: 'bottom'
                }}
                dataSource={data}
                renderItem={item => (
                    <li>
                        <Comment
                            author={item.author}
                            avatar={
                                <Avatar
                                    size='large'
                                    src={item.avatar}
                                    alt="Han Solo"
                                />
                            }
                            content={item.content}
                            datetime={
                                <Tooltip
                                    title={moment('15-04-2020 23:54:44', 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')}>
                                    <span>{moment('15-04-2020 23:54:44', 'DD-MM-yyyy HH:mm:ss').fromNow()}</span>
                                </Tooltip>
                            }
                        />
                    </li>
                )}
            />
        );
    }
}

export default Bulletin;