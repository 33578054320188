import User from "../../model/user/user";

export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';

// state
export interface LoginState {
    user: User;
    isLogged: boolean;
}

// actions
interface LoginAction {
    type: typeof LOG_IN
    user: User
}

interface LogoutAction {
    type: typeof LOG_OUT
}

// export
export type LoginActionTypes =
    LoginAction | LogoutAction