import React from "react";
import {connect} from "react-redux";
import {LoginState} from "./login/types";
import {Redirect} from "react-router-dom";
import {ApplicationState} from "../store";
import {Affix, Col, Row} from "antd";
import MainHeader from "./header/MainHeader";
import GroupMenu from "./menu/GroupMenu";
import ToolContainer from "./tool/ToolContainer";

interface Props {
    loginInfo: LoginState;
    pathInfo: {
        tool: string;
        group: string;
        organization: string;
    };
}

interface State {
    tool: React.ReactNode;
}

class App extends React.Component<Props, State> {

    render() {

        const {isLogged} = this.props.loginInfo;
        if (isLogged) {
            return (
                <Row>
                    <Col xs={{span: 24}} md={{span: 24}}>
                        <Row>
                            <Col xs={{span: 24}} md={{span: 24}}>
                                <MainHeader/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 0}} md={{span: 6}} style={{paddingTop: 24, paddingBottom: 0}}>
                                <Affix offsetTop={50}>
                                    <GroupMenu/>
                                </Affix>
                            </Col>
                            <Col xs={{span: 22, offset: 1}} md={{span: 15}} style={{paddingTop: 10, paddingBottom: 48}}>
                                <ToolContainer/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        } else {
            return (<Redirect to='/login'/>);
        }
    }
}

export default connect(
    (state: ApplicationState) => {
        return {loginInfo: state.login};
    },
)(App);