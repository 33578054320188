import * as React from "react";
import {Typography} from "antd";
import packageJson from '../package.json';

export default function Version() {
    return (
        <Typography.Text code>
            Version {packageJson.version}
        </Typography.Text>
    );
}