import {combineReducers, compose, createStore, Store} from "redux";
import {LoginState} from "./app/login/types";
import loginReducer from "./app/login/loginReducer";
import {GroupMenuState} from "./app/menu/types";
import groupMenuReducer from "./app/menu/groupMenuReducer";
import {ToolMenuState} from "./app/tool/types";
import toolMenuReducer from "./app/tool/toolMenuReducer";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface ApplicationState {
    login: LoginState,
    groupMenu: GroupMenuState,
    toolMenu: ToolMenuState,
}

const store: Store<ApplicationState> = createStore(
    combineReducers<ApplicationState>({
        login: loginReducer,
        groupMenu: groupMenuReducer,
        toolMenu: toolMenuReducer,
    }),
    composeEnhancers()
);

export default store;