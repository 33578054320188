import React from "react";
import {Space, Table, Typography} from "antd";
import moment from "moment";

class Homework extends React.Component<any> {
    render() {
        const {Text} = Typography;
        const columns = [
            {
                title: 'Tarea',
                dataIndex: 'name',
                key: 'name',
                render: (text: string) => text,
            },
            {
                title: 'Entrega',
                dataIndex: 'duedate',
                key: 'duedate',
                render: (text: string) => {
                    const date = moment(text, 'DD-MM-YYYY');
                    if (date.isBefore()) {
                        return date.fromNow();
                    } else {
                        return <Text mark>{text}</Text>;
                    }
                }
            },
            {
                title: 'Ver',
                key: 'action',
                render: (text: any, record: any) => (
                    <Space size="middle">
                        <a href={'#'}><Text underline style={{color: '#3FAAFF'}}>ver</Text></a>
                    </Space>
                ),
            },
        ];
        const data = [
            {
                key: '1',
                name: 'Las células',
                duedate: moment('27-06-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '2',
                name: 'Pendiente de una recta',
                duedate: moment('27-08-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '3',
                name: 'Resumen del libro',
                duedate: moment('03-07-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '4',
                name: 'algoritmos',
                duedate: moment('01-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '5',
                name: 'Religion',
                duedate: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '6',
                name: 'vacaciones',
                duedate: moment('10-05-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '7',
                name: 'Cuentos',
                duedate: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '8',
                name: 'Historia de Chile',
                duedate: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '9',
                name: 'Filosofía Freud',
                duedate: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '10',
                name: 'Series',
                duedate: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
            {
                key: '11',
                name: 'Juegos',
                duedate: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY')
            },
        ];
        return (
            <Table columns={columns} dataSource={data} bordered={false} size='small'
                   pagination={{size: 'small', pageSize: 20}}/>
        );
    }
}

export default Homework;
