import React from "react";
import {Affix, Badge, Col, Row, Space, Typography} from "antd";
import {Link} from 'react-router-dom';
import Forum from "../forum/Forum";
import Bulletin from "../bulletin/Bulletin";
import Mark from "../mark/Mark";
import Homework from "../homework/Homework";
import {CommentOutlined, FileDoneOutlined, ThunderboltOutlined} from "@ant-design/icons";
import {ReadOutlined} from "@ant-design/icons/lib";
import {Tool} from "../../model/tool/tool";
import Group from "../../model/group/group";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import Organization from "../../model/organization/organization";
import {selectTool} from "./toolMenuActions";
import {TOOL} from "../../model/tool/constants";

interface Props {
    organization: Organization;
    group: Group;
    tool: Tool;
    onSelectTool: (t: Tool) => void;
}

class ToolContainer extends React.Component<Props> {

    renderTool = (tool: Tool): React.ReactNode => {
        window.scrollTo(0, 0);
        switch (tool.code) {
            case TOOL.FORUM.code:
                return <Forum/>;
            case TOOL.BULLETIN.code:
                return <Bulletin/>;
            case TOOL.MARK.code:
                return <Mark/>;
            case TOOL.HOMEWORK.code:
                return <Homework/>;
            default:
                return 'no hay tool';
        }
    }

    checkDefaultTool = () => {
        const {tool, group, onSelectTool} = this.props;
        if (tool.code === '' && group.tools.length > 0) {
            onSelectTool(group.tools[0]);
        }
    }

    isAvailableTool = (tool: Tool): boolean => {
        return this.props.group.tools.find((t: Tool) => t.code === tool.code) !== undefined;
    }

    render() {
        const {Text} = Typography;
        const {tool, organization, group, onSelectTool} = this.props;
        this.checkDefaultTool();
        return (
            <Row>
                <Col xs={{span: 24}}>
                    <Affix offsetTop={0}>
                        <Row style={{
                            backgroundColor: 'white',
                            padding: '10px 0px 10px 0px',
                            borderStyle: 'solid',
                            borderWidth: '0px 0px 1px 0px',
                            borderImage: 'linear-gradient(180deg, rgb(240, 240, 240), rgb(221, 219, 219)) 1'
                        }}>
                            <Col xs={{span: 24}} style={{overflow: 'auto', whiteSpace: 'nowrap'}}>
                                <Space align={'center'} size={'large'}>
                                    {this.isAvailableTool(TOOL.FORUM) &&
                                    <Link to={'/' + organization.code + '/' + group.code + '/' + TOOL.FORUM.code}
                                          onClick={() => onSelectTool(TOOL.FORUM)}
                                    >
                                        <Space size={'small'}>
                                            <Row>
                                                <Col xs={{span: 0}} md={{span: 24}}>
                                                    <CommentOutlined
                                                        style={{color: tool.code === TOOL.FORUM.code ? "black" : '#8C8C8C'}}/>
                                                </Col>
                                            </Row>
                                            <Text type={tool.code !== TOOL.FORUM.code ? "secondary" : undefined}
                                                  strong={tool.code === TOOL.FORUM.code}>Foro</Text>
                                            <Badge count={5} showZero/>
                                        </Space>
                                    </Link>}
                                    {this.isAvailableTool(TOOL.HOMEWORK) &&
                                    <Link to={'/' + organization.code + '/' + group.code + '/' + TOOL.HOMEWORK.code}
                                          onClick={() => onSelectTool(TOOL.HOMEWORK)}
                                    >
                                        <Space size={'small'}>
                                            <Row>
                                                <Col xs={{span: 0}} md={{span: 24}}>
                                                    <FileDoneOutlined
                                                        style={{color: tool.code === TOOL.HOMEWORK.code ? "black" : '#8C8C8C'}}/>
                                                </Col>
                                            </Row>
                                            <Text type={tool.code !== TOOL.HOMEWORK.code ? "secondary" : undefined}
                                                  strong={tool.code === TOOL.HOMEWORK.code}>Tareas</Text>
                                            <Badge count={0} showZero style={{backgroundColor: '#D9D9D9'}}/>
                                        </Space>
                                    </Link>}
                                    {this.isAvailableTool(TOOL.MARK) &&
                                    <Link to={'/' + organization.code + '/' + group.code + '/' + TOOL.MARK.code}
                                          onClick={() => onSelectTool(TOOL.MARK)}
                                    >
                                        <Space size={'small'}>
                                            <Row>
                                                <Col xs={{span: 0}} md={{span: 24}}>
                                                    <ThunderboltOutlined
                                                        style={{color: tool.code === TOOL.MARK.code ? "black" : '#8C8C8C'}}/>
                                                </Col>
                                            </Row>
                                            <Text type={tool.code !== TOOL.MARK.code ? "secondary" : undefined}
                                                  strong={tool.code === TOOL.MARK.code}>Notas</Text>
                                            <Badge count={0} showZero style={{backgroundColor: '#D9D9D9'}}/>
                                        </Space>
                                    </Link>}
                                    {this.isAvailableTool(TOOL.BULLETIN) &&
                                    <Link to={'/' + organization.code + '/' + group.code + '/' + TOOL.BULLETIN.code}
                                          onClick={() => onSelectTool(TOOL.BULLETIN)}
                                    >
                                        <Space size={'small'}>
                                            <Row>
                                                <Col xs={{span: 0}} md={{span: 24}}>
                                                    <ReadOutlined
                                                        style={{color: tool.code === TOOL.BULLETIN.code ? "black" : '#8C8C8C'}}/>
                                                </Col>
                                            </Row>
                                            <Text type={tool.code !== TOOL.BULLETIN.code ? "secondary" : undefined}
                                                  strong={tool.code === TOOL.BULLETIN.code}>Noticias</Text>
                                            <Badge count={1} showZero/>
                                        </Space>
                                    </Link>}
                                </Space>
                            </Col>
                        </Row>
                    </Affix>
                    <Row>
                        <Col xs={{span: 24}}>
                            {this.renderTool(this.props.tool)}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            group: state.groupMenu.selected,
            organization: state.login.user.organization,
            tool: state.toolMenu.selected,
        }
    },
    dispatch => {
        return {
            onSelectTool: (t: Tool) => dispatch(selectTool(t)),
        }
    }
)(ToolContainer);