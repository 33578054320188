import React from "react";
import {Avatar, Button, Col, Popconfirm, Row, Typography} from "antd";
import {connect} from "react-redux";
import {logout} from "../login/loginActions";
import {LogoutOutlined} from "@ant-design/icons";
import GroupMenu from "../menu/GroupMenu";
import avatar from "../../user.png";
import User from "../../model/user/user";
import {roleTranslation} from "../../model/user/constants";
import {ApplicationState} from "../../store";

interface Props {
    user: User;
    onLogout: () => void;
}

class MainHeaderMenu extends React.Component<Props> {

    render() {
        const {user, onLogout} = this.props;
        const {Text} = Typography;
        return (
            <Row>
                <Col xs={{span: 24}}>
                    <Row justify="space-between">
                        <Col xs={{span: 3, offset: 1}}>
                            <Avatar
                                src={avatar}
                                alt="Han Solo"
                            />
                        </Col>
                        <Col xs={{span: 16, offset: 0}} style={{textAlign: 'left'}}>
                            <Text strong>{user.name}</Text><br/>
                            <Text>{user.roles.map((r: string) => roleTranslation(r)).join(", ")}</Text>
                        </Col>
                        <Col xs={{span: 4, offset: 0}}>
                            <Popconfirm
                                placement="bottomRight"
                                title={'¿Quieres cerrar sesión?'}
                                okText="Si"
                                cancelText="No"
                                onConfirm={onLogout}
                            >
                                <Button type="default" shape="circle" size={"small"}
                                        icon={<LogoutOutlined style={{color: 'red'}}/>}/>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span: 24}}>
                            <GroupMenu/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            user: state.login.user
        }
    },
    dispatch => {
        return {
            onLogout: () => dispatch(logout())
        }
    }
)(MainHeaderMenu);