import React from "react";
import {Table, Typography} from "antd";
import moment from "moment";

class Mark extends React.Component<any> {
    render() {
        const {Text} = Typography;
        const columns = [
            {
                title: 'Evaluación',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Fecha',
                dataIndex: 'date',
                key: 'date',
                render: (text: string) => <Text>{text}</Text>,
            },
            {
                title: 'Nota',
                dataIndex: 'mark',
                key: 'mark',
                render: (text: string) => (
                    <Text type={parseFloat(text) < 4 ? 'danger' : undefined}>{parseFloat(text).toFixed(1)}</Text>
                ),
            },
        ];
        const data = [
            {
                key: '1',
                name: 'Las células',
                date: moment('27-06-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 7.0
            },
            {
                key: '2',
                name: 'Pendiente de una recta',
                date: moment('27-08-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 6.0
            },
            {
                key: '3',
                name: 'Resumen del libro',
                date: moment('03-07-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 6.3
            },
            {
                key: '4',
                name: 'algoritmos',
                date: moment('01-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 4.0
            },
            {
                key: '5',
                name: 'Religion',
                date: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 5.4
            },
            {
                key: '6',
                name: 'vacaciones',
                date: moment('10-05-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 3.4
            },
            {
                key: '7',
                name: 'Cuentos',
                date: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 2.0
            },
            {
                key: '8',
                name: 'Historia de Chile',
                date: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 1.0
            },
            {
                key: '9',
                name: 'Filosofía Freud',
                date: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 7.0
            },
            {
                key: '10',
                name: 'Series',
                date: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 7.0
            },
            {
                key: '11',
                name: 'Juegos',
                date: moment('27-03-2020', 'DD-MM-YYYY').format('DD-MM-YYYY'),
                mark: 7.0
            },
        ];
        return (
            <Table columns={columns} dataSource={data} bordered={false} size='small'
                   pagination={{size: 'small', pageSize: 20}}/>
        );
    }
}

export default Mark;